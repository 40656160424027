import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { IAboutAcfResponse } from '../about/models/about-acf-response.interface';

import { switchMap } from 'rxjs/operators';
import { PageService } from 'src/app/services/page.service';

@Injectable({
  providedIn: 'root'
})
export class AboutService {

  private postUrl: string;
  private postCustomFieldUrl: string;

  constructor(private http: HttpClient, private pageService: PageService) {
    this.postUrl = `${environment.baseUrl}/${environment.urls.getPage}`;
    this.postCustomFieldUrl = `${environment.baseUrl}/${environment.urls.getPageCustomFields}`;
  }

  getCustomFields(): Observable<IAboutAcfResponse> {
    return this.pageService.getPage(environment.pages.nosotros.id).pipe(
      switchMap(page=>{
        const url = `${this.postCustomFieldUrl}/${page.id}`;
        return this.http.get<IAboutAcfResponse>(url)
      })
    );
  }
}
