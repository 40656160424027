import { Component, OnInit } from '@angular/core';
import { ILogos } from '../../models/logos.interface';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AboutService } from '../../../pages/about/about.service';
import { IAboutAcfResponse } from '../../../pages/about/models/about-acf-response.interface';

@Component({
  selector: 'app-logos',
  templateUrl: './logos.component.html',
  styleUrls: ['./logos.component.scss']
})
export class LogosComponent implements OnInit {

  logoAbout$:Observable<ILogos[]>;

  constructor(private aboutService: AboutService) { }

  ngOnInit(): void {
    this.logoAbout$ = this.aboutService.getCustomFields().pipe(map((response: IAboutAcfResponse) => {
      const { acf } = response;
      const logoAboutItems: ILogos[] = [];

      for (const key in acf) {
        if (Object.prototype.hasOwnProperty.call(acf, key) && key.startsWith('contenido_logo_')) {
          //const element = acf[key];
          logoAboutItems.push(acf[key]);
        }
      }

      return logoAboutItems;
    }));
  }

}
